* {
    margin: 0;
    padding: 0;
}

body {
    background: #548db1;
    background: -moz-linear-gradient(135deg, #548db1 0%, #544d92 100%);
    background: -webkit-linear-gradient(135deg, #548db1 0%, #544d92 100%);
    background: linear-gradient(135deg, #548db1 0%, #544d92 100%);
}

#root {
    width: 100%;
    height: 100%;
}
